export interface SchoolType {
  label: string;
  shortLabel: string;
  value: string;
  color: string;
  icon: {
    default: {
      src: string;
      width: number;
      height: number;
    };
    selected: {
      src: string;
      width: number;
      height: number;
    };
  };
}

export const SCHOOL_TYPES: SchoolType[] = [
  {
    value: 'grammar',
    label: 'Selective Grammar Schools',
    shortLabel: 'Select Grammar',
    color: '#823b8f',
    icon: {
      default: {
        src: '/map/grammar-marker.svg',
        width: 36,
        height: 55,
      },
      selected: {
        src: '/map/selected-school-marker.svg',
        width: 36,
        height: 55,
      },
    },
  },
  {
    value: 'integrated',
    label: 'Integrated Colleges',
    shortLabel: 'Integrated',
    color: '#C1251B',
    icon: {
      default: {
        src: '/map/integrated-marker.svg',
        width: 36,
        height: 55,
      },
      selected: {
        src: '/map/selected-school-marker.svg',
        width: 36,
        height: 55,
      },
    },
  },
  {
    shortLabel: 'Secondary',
    value: 'secondary',
    label: 'Secondary Schools',
    color: '#E6B43A',
    icon: {
      default: {
        src: '/map/secondary-marker.svg',
        width: 36,
        height: 55,
      },
      selected: {
        src: '/map/selected-school-marker.svg',
        width: 36,
        height: 55,
      },
    },
  },
  {
    shortLabel: 'Primary',
    value: 'primary',
    label: 'Primary Schools',
    color: '#5DB1DE',
    icon: {
      default: {
        src: '/map/primary-marker.svg',
        width: 36,
        height: 55,
      },
      selected: {
        src: '/map/selected-school-marker.svg',
        width: 36,
        height: 55,
      },
    },
  },
];

export const ROI_SCHOOL_TYPES = [
  {
    value: 'roipostprimary',
    label: 'Post Primary Schools',
    shortLabel: 'Post Primary',
    color: '#DFABC9',
    icon: {
      default: {
        src: '/map/roi-post-primary-marker.svg',
        width: 36,
        height: 55,
      },
      selected: {
        src: '/map/selected-school-marker.svg',
        width: 36,
        height: 55,
      },
    },
  },
  {
    value: 'roiprimary',
    label: 'Primary Schools',
    shortLabel: 'Primary',
    color: '#2C9B3F',
    icon: {
      default: {
        src: '/map/roi-primary-marker.svg',
        width: 36,
        height: 55,
      },
      selected: {
        src: '/map/selected-school-marker.svg',
        width: 36,
        height: 55,
      },
    },
  },
];
