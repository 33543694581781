import { faArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp';
import useInView from '@propertypal/shared/src/hooks/useInView';
import { MortgageCalcData } from '@propertypal/shared/src/hooks/useMortgageCalculator';
import CoOwnershipLogo from '@propertypal/shared/src/resources/logos/co-ownership-logo.svg?url';
import { getItem } from '@propertypal/shared/src/services/local.storage';
import { Property, KeyInfo } from '@propertypal/shared/src/types/property';
import floatToCurrency, { parseNumber } from '@propertypal/shared/src/utils/floatToCurrency';
import {
  getDefaultDeposit,
  getDefaultTerm,
} from '@propertypal/shared/src/utils/property/getMortgageCalculatorDefaults';
import groupKeyInfo, { KeyInfoCell } from '@propertypal/shared/src/utils/property/groupKeyInfo';
import Link from 'next/link';
import React, { FunctionComponent, useRef } from 'react';
import Loader from 'react-spinners/PulseLoader';
import { DefaultTheme, useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Text } from '../typography';
import EnergyRatingIcon from './EnergyRatingIcon';
import { Container, LinkText, Row, TableColumn, TBody, TextHtml, TextRow } from './KeyInfoBox.style';

interface Props {
  buyingStatus: string | null;
  property: Property;
  onClick: (info: KeyInfo) => void;
  mortgageCalculator: MortgageCalcData;
}

const HIGHLIGHTED_ROWS = ['Sale agreed', 'Let agreed', 'Sold', 'Let'];

const STAMP_DUTY_TEXT_MAP: { [key: string]: string } = {
  FIRST_TIME_BUYER: 'First Time Buyer',
  HOME_MOVER: 'Home Mover',
  BUY_TO_LET_INVESTOR: 'Buy-to-Let Investor',
  ADDITIONAL_HOME_BUYER: 'Second Home Buyer',
};

const renderFeesLink = (accNumber: string) => {
  return (
    <Link href={`/letting-agent-fees/${accNumber}`} prefetch={false}>
      {' '}
      <LinkText as="span" data-testid="fees-link">
        (some fees may apply)
      </LinkText>
    </Link>
  );
};

const renderText = (
  info: KeyInfoCell,
  property: Property,
  color: string,
  onClick: Props['onClick'],
  buyingStatus: string | null,
  mortgageCalcLoading: boolean,
  theme: DefaultTheme,
) => {
  const handleClick = () => onClick(info);

  if (info.buyerTypeCosts && buyingStatus) {
    return (
      <Text data-testid="stampDutyText">
        {info.buyerTypeCosts[buyingStatus]}*² (Based on being a {STAMP_DUTY_TEXT_MAP[buyingStatus]}){' '}
        <LinkText
          as="button"
          aria-label="Change buying status"
          className="pp-stamp-duty-text"
          onClick={handleClick}
          type="button"
        >
          Change
        </LinkText>
      </Text>
    );
  }

  if (info.key === 'STAMP_DUTY_COMPLEX') {
    return (
      <LinkText
        as="button"
        className="pp-stamp-duty-text"
        data-testid="stampDutyText"
        onClick={handleClick}
        type="button"
      >
        Calculate Stamp Duty
      </LinkText>
    );
  }

  if (info.key === 'TYPICAL_MORTGAGE') {
    if (mortgageCalcLoading) {
      return (
        <span data-testid="typical-mortgage-loading">
          <Loader color={theme.backgroundLight} />
        </span>
      );
    }

    if (!info.text) {
      return (
        <Text data-testid="no-mortgage-result">
          No results, try changing your{' '}
          <LinkText onClick={handleClick} as="span">
            mortgage criteria
          </LinkText>{' '}
          below
        </Text>
      );
    }

    return (
      <>
        <LinkText as="p" onClick={handleClick} data-testid="typical-mortgage-text" className="typical-mortgage-text">
          {info.text}
        </LinkText>
        {info.subtext && (
          <Text data-testid="typical-mortgage-subtext" fontSize={13} color={theme.textLight}>
            {info.subtext}
          </Text>
        )}
      </>
    );
  }

  if (info.key === 'EPC' && info.epcRows) {
    return info.epcRows.map((epcInfo) => (
      <LinkText
        as="button"
        aria-label="View EPC file"
        className="pp-epc-text"
        data-testid="epcText"
        onClick={() => onClick(epcInfo)}
        key={epcInfo.text}
        type="button"
      >
        {epcInfo.key === 'EPC_FILES' && 'EPC - '}
        {epcInfo.text}
      </LinkText>
    ));
  }

  if (info.key === 'PRICE') {
    return (
      <Text className="pp-key-info-price">
        {!!info.beforePrice && `${info.beforePrice} `}
        <b style={{ color }}>{info.price}</b>
        {!!info.afterPrice && ` ${info.afterPrice}`}
        {property.includeFeeCharges && renderFeesLink(property.account.accountNumber)}
      </Text>
    );
  }

  if (info.key === 'RATES') {
    return <Text>{info.text}*¹</Text>;
  }

  if (info.key === 'STAMP_DUTY_SIMPLE') {
    return <Text>{info.text}*²</Text>;
  }

  if (info.key === 'BER') {
    return <EnergyRatingIcon property={property} />;
  }

  if (info.key === 'BROADBAND' && property.ofcomBroadband) {
    return (
      <>
        <TextRow data-testid="broadbandSpeed">
          <FontAwesomeIcon icon={faArrowDown} color={theme.primary} />
          <Text>
            Highest download speed: <b>{property.ofcomBroadband.maxPredictedDown} Mbps</b>
          </Text>
        </TextRow>

        <TextRow>
          <FontAwesomeIcon icon={faArrowUp} color={theme.primary} />
          <Text>
            Highest upload speed: <b>{property.ofcomBroadband.maxPredictedUp} Mbps</b>
          </Text>
        </TextRow>

        <TextRow>
          <Text>
            <LinkText onClick={handleClick} as="button" type="button">
              More details
            </LinkText>{' '}
            *³
          </Text>
        </TextRow>
      </>
    );
  }

  if (info.key === 'COOWNERSHIP') {
    return <TextHtml dangerouslySetInnerHTML={{ __html: info.textHtml }} />;
  }

  if (info.link) {
    return (
      <LinkText>
        <a href={info.link} target="_blank" rel="noreferrer">
          {info.text}
        </a>
      </LinkText>
    );
  }

  return <Text>{info.text}</Text>;
};

const KeyInfoBox: FunctionComponent<Props> = (props) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLTableElement>(null);
  const { data, loading, mortgagePayment, hasResult, depositPercentage, hasMortgageCalculator, getMortgageResult } =
    props.mortgageCalculator;

  const getKeyInfo = () => {
    if (props.property.keyInfo && hasMortgageCalculator) {
      const typicalMortgage: KeyInfo = {
        key: 'TYPICAL_MORTGAGE',
        name: 'Typical Mortgage',
        text: '',
        textHtml: '',
        group: '',
        subtext: null,
        image: null,
        link: null,
      };

      if (!loading && data && mortgagePayment && hasResult) {
        const text = !loading && data && mortgagePayment ? `${mortgagePayment} per month` : '';
        typicalMortgage.text = text;
        typicalMortgage.textHtml = text;
        typicalMortgage.subtext = `${floatToCurrency(data.deposit, {
          precision: 0,
        })} (${depositPercentage}%) deposit (${data.term} year term)`;
      }

      const keyInfo = [...props.property.keyInfo];
      keyInfo.splice(3, 0, typicalMortgage);
      return keyInfo;
    }

    return props.property.keyInfo;
  };

  const keyInfo = getKeyInfo();

  const fetchMortgageResult = () => {
    if (!data && hasMortgageCalculator) {
      const defaultTerm = getItem<string>('defaultTerm');
      const defaultDeposit = getItem<string>('defaultDeposit');

      getMortgageResult({
        propertyValue: props.property.price?.price,
        paymentTerm: defaultTerm ? parseNumber(defaultTerm) : getDefaultTerm(props.property),
        deposit: defaultDeposit ? parseNumber(defaultDeposit) : getDefaultDeposit(props.property),
      });
    }
  };

  useInView(containerRef, { threshold: 0.5, rootMargin: '0px 0px 600px' }, fetchMortgageResult);

  if (!props.property.keyInfo) {
    return null;
  }

  return (
    <Container className="pp-key-info" ref={containerRef}>
      <TBody>
        {groupKeyInfo(keyInfo).map((info, index, keyInfoCells) => {
          const isHighlighted = HIGHLIGHTED_ROWS.includes(info.text);
          const isOrphan = index + 1 === keyInfoCells.length && keyInfoCells.length % 2 !== 0;

          return (
            <Row
              key={info.key}
              className={isHighlighted ? 'pp-key-info-row highlighted' : 'pp-key-info-row'}
              isOrphan={isOrphan}
              totalRows={groupKeyInfo(keyInfo).length}
            >
              <TableColumn isHighlighted={isHighlighted}>
                {info.key === 'COOWNERSHIP' && (
                  <img src={CoOwnershipLogo.src} className="co-ownership-logo" alt="Co-Ownership" />
                )}

                {info.key !== 'COOWNERSHIP' && (
                  <Text data-testid={`key-info-row-${index + 1}`} fontWeight="600">
                    {info.name}
                  </Text>
                )}
              </TableColumn>

              <TableColumn isHighlighted={isHighlighted}>
                {renderText(info, props.property, theme.primary, props.onClick, props.buyingStatus, loading, theme)}
              </TableColumn>
            </Row>
          );
        })}
      </TBody>
    </Container>
  );
};

export default KeyInfoBox;
