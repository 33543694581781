import { trackGaLink } from '@propertypal/shared/src/services/analytics';
import { Property } from '@propertypal/shared/src/types';
import Link from 'next/link';
import React from 'react';
import Chevron from '../icons/Chevron';
import { Bold, LinkText, Text, TextProps } from '../typography';

interface PromotePropertyProps {
  property: Property;
  label: string;
  align?: TextProps['align'];
}

const PromoteProperty: React.FC<PromotePropertyProps> = ({ property, label, align = 'center' }) => {
  return (
    <Text align={align} mt={15} mb={30} data-testid="pdp-footer-promote">
      <Bold>Is this your property?</Bold> Attract more {property.saleType.key === 'rent' ? 'tenants' : 'buyers'} by
      upgrading your listing.{' '}
      <Link href={`${property.path}/promote`} onClick={trackGaLink('premium_click', label, 'PDP')} prefetch={false}>
        <LinkText as="span" data-testid={`pdp-footer-promote-${label}`}>
          See options and pricing <Chevron size={10} />
        </LinkText>
      </Link>
    </Text>
  );
};

export default PromoteProperty;
