import { Contact, Property } from '@propertypal/shared/src/types/property';
import { getAwardFromContact } from '@propertypal/shared/src/utils/property/getAward';
import useCallAgent from '@propertypal/web-app/src/utils/useCallAgent';
import React, { FunctionComponent } from 'react';
import Button from '../buttons/Button';
import { Row } from '../layout';
import { SubHeading } from '../typography';
import { CallAgentBox, Column, Container, ShowPhoneNumber, Table, TableRow } from './ContactTable.style';

interface Props {
  property: Property;
  onEnquiryClick: (contact: Contact) => void;
  onRegisterInterestClick: (contact: Contact) => void;
  registerInterest?: boolean;
}

interface ItemProps {
  property: Property;
  contact: Contact;
  onEnquiryClick: (contact: Contact) => void;
  onRegisterInterestClick?: (contact: Contact) => void;
  registerInterest?: boolean;
}

const ContactTableItem: FunctionComponent<ItemProps> = ({
  contact,
  property,
  onEnquiryClick,
  onRegisterInterestClick,
  registerInterest,
}) => {
  const { callAgent, modal, showPhoneModal } = useCallAgent();
  const award = getAwardFromContact(contact);
  const title = contact.developer ? 'Developer' : 'Agent';
  const page = property.type === 'development' ? 'DDP' : 'PDP';

  return (
    // When agent has an award and has register interest button text should sit below logos so it doesn't stretch layout
    <TableRow key={contact.accountNumber} className="pp-table-row" registerInterest={registerInterest}>
      <Column className="pp-table-cell" column={award && registerInterest}>
        <Row>
          <img src={contact.logos?.standard} alt={`${contact.organisation} logo`} />

          {award && <img src={award.imageUrl} alt={award.label} />}
        </Row>

        <h4>{contact.organisation}</h4>
      </Column>

      {registerInterest && onRegisterInterestClick && (
        <Column className="pp-table-cell">
          <Button
            onClick={() => onRegisterInterestClick(contact)}
            testID={`registerInterest${contact.accountNumber}`}
            className="pp-register-interest"
          >
            Register Your Interest
          </Button>
        </Column>
      )}

      <Column className="pp-table-cell">
        {!contact.phone && contact.phoneNumber && <h4>Please contact the selling agents.</h4>}

        {contact.phone && contact.phoneNumber && (
          <>
            <ShowPhoneNumber>
              <Button
                onClick={() => showPhoneModal(property, contact, undefined, page, 'Contact Table')}
                testID={`showNumber${contact.accountNumber}`}
              >
                Call the {title}
              </Button>
            </ShowPhoneNumber>

            <CallAgentBox>
              <Button
                onClick={() => callAgent(property, contact, undefined, page, 'Contact Table')}
                testID={`callAgent${contact.accountNumber}`}
                type="button"
              >
                Call the {title}
              </Button>
            </CallAgentBox>

            {modal}
          </>
        )}
      </Column>

      <Column className="pp-table-cell">
        {contact.email && (
          <Button onClick={() => onEnquiryClick(contact)} testID={`enquireNow${contact.accountNumber}`}>
            {registerInterest ? `Email The ${title}` : 'Enquire Now'}
          </Button>
        )}
      </Column>
    </TableRow>
  );
};

const ContactTable: FunctionComponent<Props> = (props) => {
  if (!props.property.contacts?.all) {
    return null;
  }

  const agents = props.property.contacts.all.filter((contact) => contact.agent);
  const developers = props.property.contacts.all.filter((contact) => contact.developer);

  return (
    <Container className="pp-contact-table" registerInterest={props.registerInterest}>
      <SubHeading mb={30}>Contact the Agent</SubHeading>

      <Table className="pp-table">
        {agents.length > 0 && (
          <>
            <TableRow heading className="pp-table-head" registerInterest={props.registerInterest}>
              <Column className="pp-table-cell">
                <h4>Selling Agent</h4>
              </Column>

              {props.registerInterest && (
                <Column className="pp-table-cell">
                  <h4>Register Your Interest</h4>
                </Column>
              )}

              <Column className="pp-table-cell">
                <h4>Phone Number</h4>
              </Column>

              <Column className="pp-table-cell">
                <h4>Email</h4>
              </Column>
            </TableRow>

            {agents.map((agent) => (
              <ContactTableItem
                key={agent.accountNumber}
                property={props.property}
                contact={agent}
                onEnquiryClick={props.onEnquiryClick}
                onRegisterInterestClick={props.onRegisterInterestClick}
                registerInterest={props.registerInterest}
              />
            ))}
          </>
        )}

        {developers.length > 0 && (
          <>
            <TableRow heading className="pp-table-head" registerInterest={props.registerInterest}>
              <Column className="pp-table-cell">
                <h4>Developer</h4>
              </Column>

              <Column className="pp-table-cell">
                <h4>Phone Number</h4>
              </Column>

              <Column className="pp-table-cell">
                <h4>Email</h4>
              </Column>
            </TableRow>

            {developers.map((developer) => (
              <ContactTableItem
                key={developer.accountNumber}
                property={props.property}
                contact={developer}
                onEnquiryClick={props.onEnquiryClick}
              />
            ))}
          </>
        )}
      </Table>
    </Container>
  );
};

export default ContactTable;
