import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight';
import useWindowSize from '@propertypal/shared/src/hooks/useWindowSize';
import { Property } from '@propertypal/shared/src/types/property';
import { GalleryItem } from '@propertypal/shared/src/utils/property/getGalleryImages';
import useTouchDevice from '@propertypal/web-app/src/hooks/useTouchDevice';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { ArrowButton, Container, HtmlWrapper, Image, SLIDE_WIDTH, SliderBox } from './DevelopmentHeader.style';
import PropertyBanner from './PropertyBanner';

interface Props {
  html: string;
  property: Property;
  images: GalleryItem[];
  onOpenSlideshowClick: () => void;
  onImageClick: (index: number) => void;
}

const DevelopmentHeader: FunctionComponent<Props> = (props) => {
  const windowSize = useWindowSize();
  const theme = useTheme();
  const touchDevice = useTouchDevice();
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [slidesPerGroup, setSlidesPerGroup] = useState<number | null>(null);

  useEffect(() => {
    if (windowSize.width && slidesPerGroup === null) {
      setSlidesPerGroup(Math.floor(windowSize.width / SLIDE_WIDTH));
    }
  }, [windowSize.width]);

  useEffect(() => {
    const els = document.querySelectorAll('.pp-content-top a');

    if (els) {
      els.forEach((el) => {
        el.addEventListener('click', (event) => {
          event.stopPropagation();
        });
      });
    }
  }, []);

  return (
    <Container>
      <PropertyBanner property={props.property} disableFeatured disablePremium />

      <HtmlWrapper
        aria-label="Open image gallery"
        className="pp-content-top"
        onClick={props.onOpenSlideshowClick}
        type="button"
        data-testid="openDevelopmentSlideshow"
        dangerouslySetInnerHTML={{ __html: props.html }}
      />

      {props.property.type === 'property' && slidesPerGroup !== null && (
        <SliderBox>
          {!touchDevice && (
            <>
              <ArrowButton aria-label="Previous image" side="left" onClick={() => swiper?.slidePrev()}>
                <FontAwesomeIcon icon={faAngleLeft} color={theme.white} style={{ fontSize: 60 }} />
              </ArrowButton>

              <ArrowButton aria-label="Next image" side="right" onClick={() => swiper?.slideNext()}>
                <FontAwesomeIcon icon={faAngleRight} color={theme.white} style={{ fontSize: 60 }} />
              </ArrowButton>
            </>
          )}

          <Swiper
            className="pp-development-thumbnails"
            onSwiper={setSwiper}
            slidesPerView="auto"
            spaceBetween={10}
            slidesPerGroup={slidesPerGroup}
            simulateTouch
          >
            {props.images?.map((image, mIndex) => (
              <SwiperSlide key={image.image} style={{ width: 256 }}>
                <Image
                  className="pp-development-thumbnail"
                  url={image.image}
                  onClick={() => props.onImageClick(mIndex)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </SliderBox>
      )}
    </Container>
  );
};

export default DevelopmentHeader;
