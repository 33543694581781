import styled from 'styled-components';

export const AwardsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const AwardBox = styled.a<{ isWinner: boolean }>`
  border: 2px solid ${(props) => (props.isWinner ? props.theme.gold : '#47203E')};
  border-radius: 4px;
  padding: 10px;
  margin: 0 15px 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 220px;

  img {
    max-height: 56px;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    font-weight: 600;
    color: ${(props) => props.theme.textDark};
    text-align: center;
  }
`;
