import { faBallotCheck } from '@fortawesome/pro-regular-svg-icons/faBallotCheck';
import { faBath } from '@fortawesome/pro-regular-svg-icons/faBath';
import { faBedAlt } from '@fortawesome/pro-regular-svg-icons/faBedAlt';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding';
import { faCalculator } from '@fortawesome/pro-regular-svg-icons/faCalculator';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faChair } from '@fortawesome/pro-regular-svg-icons/faChair';
import { faChartBar } from '@fortawesome/pro-regular-svg-icons/faChartBar';
import { faChimney } from '@fortawesome/pro-regular-svg-icons/faChimney';
import { faCity } from '@fortawesome/pro-regular-svg-icons/faCity';
import { faCouch } from '@fortawesome/pro-regular-svg-icons/faCouch';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import { faGarage } from '@fortawesome/pro-regular-svg-icons/faGarage';
import { faGavel } from '@fortawesome/pro-regular-svg-icons/faGavel';
import { faHome } from '@fortawesome/pro-regular-svg-icons/faHome';
import { faHotel } from '@fortawesome/pro-regular-svg-icons/faHotel';
import { faIndustryAlt } from '@fortawesome/pro-regular-svg-icons/faIndustryAlt';
import { faMoneyBill } from '@fortawesome/pro-regular-svg-icons/faMoneyBill';
import { faParking } from '@fortawesome/pro-regular-svg-icons/faParking';
import { faPercent } from '@fortawesome/pro-regular-svg-icons/faPercent';
import { faRectangleWide } from '@fortawesome/pro-regular-svg-icons/faRectangleWide';
import { faRulerCombined } from '@fortawesome/pro-regular-svg-icons/faRulerCombined';
import { faSign } from '@fortawesome/pro-regular-svg-icons/faSign';
import { faStore } from '@fortawesome/pro-regular-svg-icons/faStore';
import { faTag } from '@fortawesome/pro-regular-svg-icons/faTag';
import { faTemperatureHigh } from '@fortawesome/pro-regular-svg-icons/faTemperatureHigh';
import { faTractor } from '@fortawesome/pro-regular-svg-icons/faTractor';
import { faUtensils } from '@fortawesome/pro-regular-svg-icons/faUtensils';
import { faWarehouse } from '@fortawesome/pro-regular-svg-icons/faWarehouse';
import { faWifi } from '@fortawesome/pro-regular-svg-icons/faWifi';
import { faHomeLgAlt } from '@fortawesome/pro-solid-svg-icons/faHomeLgAlt';
import { KeyInfo, Property } from '../../types/property';

export interface KeyInfoCell extends KeyInfo {
  epcRows?: KeyInfo[];
}

const EPC_KEYS = ['EPC_RATING_CHART', 'EPC_RATING', 'EPC_FILES'];

export const ICON_MAP: { [key: string]: any } = {
  TENURE: faFileAlt,
  EPC_RATING: faChartBar,
  EPC_FILES: faChartBar,
  EPC_RATING_CHART: faChartBar,
  BER: faChartBar,
  BROADBAND: faWifi,
  PRICE: faTag,
  STYLE: faHome,
  BEDROOMS: faBedAlt,
  BATHROOMS: faBath,
  RECEPTIONROOMS: faCouch,
  STATUS: faSign,
  HEATING_TYPE: faTemperatureHigh,
  STAMP_DUTY_SIMPLE: faCalculator,
  STAMP_DUTY_COMPLEX: faCalculator,
  RATES: faPercent,
  RENT_INCLUDES_RATES: faPercent,
  SIZE: faRulerCombined,
  EXTERNAL_AREA: faRulerCombined,
  INTERNAL_AREA: faRulerCombined,
  VIEWABLE_FROM: faCalendarAlt,
  AVAILABLE_FROM: faCalendarAlt,
  DEPOSIT: faMoneyBill,
  FURNISHED: faChair,
  LAND_TYPE: faChimney,
  PLANNING_PERMISSION: faChimney,
  AUCTION_DATE: faCalendarAlt,
  AUCTION_VENUE: faGavel,
  AUCTION_LOT: faBallotCheck,
  AUCTION_VENDOR: faGavel,
  LEASE: faFileAlt,
  YEAR_BUILT: faHome,
  OPEN_VIEWING: faCalendarAlt,
};

export const STYLE_ICON_MAP: { [key: string]: any } = {
  // Commercial
  Office: faBuilding,
  Offices: faBuilding,
  'Office Share': faBuilding,
  'Retail Unit': faStore,
  'Licenced/Pub': faHotel,
  'Restaurant/Cafe': faUtensils,
  'Warehouse/Storage': faWarehouse,
  'Hotel/B&B': faHotel,
  Workshop: faWarehouse,
  'Car Park': faParking,
  'Industrial Unit': faIndustryAlt,
  'Investment Property': faBuilding,
  Business: faBuilding,
  Garage: faGarage,
  'Commercial Property': faCity,
  'Commercial / Industrial': faCity,
  'Commercial Unit': faCity,
  'Freehold Industrial': faIndustryAlt,
  'Freehold Offices': faBuilding,
  'Freehold Shops': faStore,
  'Industrial Property': faIndustryAlt,
  'Leasehold Industrial': faIndustryAlt,
  'Leasehold Offices': faBuilding,
  'Leasehold Shops': faStore,
  'Licensed Premises': faHotel,
  'Lock Up Garage': faGarage,
  Pub: faHotel,
  Restaurant: faUtensils,
  Retail: faStore,
  Shop: faStore,
  'Storage Unit': faWarehouse,
  Warehouse: faWarehouse,
  Yard: faRectangleWide,
  'Shop With Living Accomodation': faStore,
  'Shop With Living Accommodation': faStore,
  'Liquor License': faHotel,
  'Serviced Office': faBuilding,
  'Co-Working Space': faBuilding,
  Apartments: faBuilding,
  'Fast Food Takeaway': faUtensils,
  'Retail/Dining': faUtensils,
  'Redevelopment Opportunity': faRectangleWide,
  'Shop With Office Accomodation': faStore,
  'Shop With Office Accommodation': faStore,
  // Land
  Farm: faTractor,
  'Farm or Small Holding': faTractor,
  'Residential Land': faRectangleWide,
  'Agricultural Land': faTractor,
  'Commercial Land': faRectangleWide,
  'Industrial Land': faRectangleWide,
  'Development Land': faRectangleWide,
  Site: faRectangleWide,
  'Building Plot': faChimney,
  'Building Site': faChimney,
  'Grass Field': faRectangleWide,
  'Potential Building Site': faChimney,
  Smallholding: faRectangleWide,
  'Development Opportunity': faRectangleWide,
  'Wood Land': faRectangleWide,
  'Equestrian Facility': faRectangleWide,
  'Detached Farmhouse': faHomeLgAlt,
};

export const getStyleIcon = (infoKey: string) => {
  const key = infoKey.replace('(with FPP)', '').replace('(with no PP)', '').replace('(with OPP)', '').trim();

  if (key in STYLE_ICON_MAP) {
    return STYLE_ICON_MAP[key];
  }

  return ICON_MAP.STYLE;
};

export const STAMP_DUTY_TEXT_MAP: { [key: string]: string } = {
  FIRST_TIME_BUYER: 'First Time Buyer',
  HOME_MOVER: 'Home Mover',
  BUY_TO_LET_INVESTOR: 'Buy-to-Let Investor',
  ADDITIONAL_HOME_BUYER: 'Second Home Buyer',
};

export const groupOverview = (keyInfo?: KeyInfo[]) => {
  return keyInfo?.filter((info) => info.group === 'OVERVIEW' && !info.key.includes('AUCTION'));
};

export const groupDetailedFeatures = (keyInfo?: KeyInfo[]) => {
  return keyInfo?.filter((info) => info.group === 'DETAIL');
};

export const groupAuctionDetails = (keyInfo?: KeyInfo[]) => {
  return keyInfo?.filter((info) => info.key.includes('AUCTION'));
};

export const groupFinancialInfo = (keyInfo?: KeyInfo[]) => {
  return keyInfo?.filter((info) => info.group === 'FINANCIAL' && info.key !== 'COOWNERSHIP');
};

export const groupViewInfo = (keyInfo?: KeyInfo[]) => {
  return keyInfo?.filter((info) => info.group === 'ENGAGEMENT');
};

const groupKeyInfo = (keyInfo: Property['keyInfo']): KeyInfoCell[] => {
  let cells: KeyInfoCell[] = [];

  if (keyInfo) {
    cells = keyInfo.filter((row) => !EPC_KEYS.includes(row.key));
    const epcFiles = keyInfo.filter((info) => EPC_KEYS.includes(info.key));
    const index = keyInfo.findIndex((info) => EPC_KEYS.includes(info.key));

    if (epcFiles.length > 0) {
      const epcRow: KeyInfoCell = {
        key: 'EPC',
        image: null,
        link: null,
        name: 'EPC',
        subtext: '',
        text: '',
        textHtml: '',
        group: '',
        epcRows: epcFiles,
      };

      cells.splice(index, 0, epcRow);
    }
  }

  return cells;
};

export default groupKeyInfo;
