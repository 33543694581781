import { Accreditation } from '@propertypal/shared/src/types/agents';
import React, { CSSProperties, FunctionComponent } from 'react';
import { SubHeading } from '../typography';
import { AwardBox, AwardsRow } from './AgentAwards.style';

interface Props {
  containerStyle?: CSSProperties;
  awards?: Accreditation[] | null;
}

const AgentAwards: FunctionComponent<Props> = (props) => {
  if (!props.awards?.length) {
    return null;
  }

  return (
    <div style={props.containerStyle} data-testid="agent-awards">
      <SubHeading fontSize={20} mb={15}>
        PropertyPal Awards
      </SubHeading>

      <AwardsRow>
        {props.awards.map((award) => (
          <AwardBox
            key={award.textKey}
            href={award.url}
            target="_blank"
            rel="noreferrer"
            isWinner={award.textKey.includes('Winner')}
          >
            <img src={award.imageUrl} alt={award.label} />
            <p>{award.label}</p>
          </AwardBox>
        ))}
      </AwardsRow>
    </div>
  );
};

export default AgentAwards;
