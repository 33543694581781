import { Contact, Property } from '../../types/property';

export const getAward = (property: Property) => {
  if (property.agents && property.agents.length > 1) {
    return null;
  }

  return property.account.propertypalAwards?.length ? property.account.propertypalAwards[0] : null;
};

export const getAwardFromContact = (contact?: Contact) => {
  return contact?.propertypalAwards?.length ? contact.propertypalAwards[0] : null;
};
