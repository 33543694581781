import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.table`
  background: ${(props) => props.theme.background};
  width: 100%;
  border: 1px solid ${(props) => props.theme.keyline};
  border-bottom: none;
  margin-bottom: 20px;
  border-collapse: collapse;
`;

export const TBody = styled.tbody`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const Row = styled.tr<{ isOrphan?: boolean; totalRows?: number }>`
  display: flex;
  width: ${(props) => (props.isOrphan ? '100%' : '50%')};
  border-bottom: 1px solid ${(props) => props.theme.keyline};

  td:first-child {
    width: 160px;
  }

  td:last-child {
    flex: 1;
  }

  &:nth-child(odd) td:first-child {
    border-left: none;
  }

  @media (${mobileXXLargeMax}) {
    width: 100%;

    td:first-child {
      width: 130px;
    }

    &:nth-child(even) td:first-child {
      border-left: none;
    }
  }

  @media print {
    border-bottom: 1px solid ${(props) => props.theme.keyline} !important;
  }
`;

export const TableColumn = styled.td<{ isHighlighted: boolean }>`
  padding: 20px 15px;
  border-left: 1px solid ${(props) => props.theme.keyline};

  &:nth-child(odd) {
    background-color: ${(props) => props.theme.backgroundLightest};
  }

  .co-ownership-logo {
    width: 100%;
    max-width: 112px;
  }

  ${(props) =>
    props.isHighlighted &&
    `
    background-color: ${props.theme.primary};
    
    &:nth-child(odd) {
      background-color: ${props.theme.primary};
    }
    
    p {
      color: ${props.theme.white};
    }
  `}
`;

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin: 2px 5px 0 0;
  }

  button {
    color: ${(props) => props.theme.primary};
    font-size: 16px;
    text-decoration: underline;
  }
`;

export const TextHtml = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.textMid};

  a {
    color: #e83a4f;
    text-decoration: underline;
  }
`;

export const LinkText = styled.p`
  color: ${(props) => props.theme.primary};
  text-decoration: underline;
  cursor: pointer;
  transition: 300ms all;
  font-size: 16px;
  text-align: left;

  &:hover {
    color: ${(props) => props.theme.backgroundMid};
  }
`;
